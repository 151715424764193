import { global, getSiteAbsoluteURL } from "../Common/saop-common";
import { SaopWebGrid,SaopWebGridProperties,SaopWebGridToolbar,SaopWebGridToolbarButton,SaopWebDataTable} from "../Components/saop-grid";
import * as moment from '../../node_modules/moment/moment';
import { SaopTreeTable, SaopTreeTableParams } from "../Components/saop-TreeTable";

export class LIKVNivojiPotrjevanjaGrid {
    private libName:string;
    //
    public webGrid1:SaopWebGrid;
    public treeTable:SaopTreeTable;
    
    constructor(libName: string) {
        this.libName = libName;
        //
      }  
      
      initView(idVrste:string){
        var _siteUrl = getSiteAbsoluteURL("LikvConfirmations");

        this.treeTable  = new SaopTreeTable("");     
        let _params:SaopTreeTableParams;
        _params = {
          id:'#LIKVNivojiPotrjevanjaGrid'
          ,url: _siteUrl+'/GetUncDocumentsData?idVrste='+idVrste
          ,columns:
            [
              {"data": "status",
                render: function(data:any, type:any, row:any){
                  var _div = 
                  `
                  <div style=" display: flex;">
                    <div class="my-auto dotStatus" style="background-position: left;background-color:${row["barvaStatusa"]}"></div>
                    <div class="my-auto" style="padding-left:5px;">${row["nazivStatusa"]}</div>
                  </div>    
                  `;
                  return _div;    
                }
              },
              {"data": "nazivAkcije", className: 'saop-table-text-truncate pr-2'},
              {"data": "nazivPodpisnik", className: 'saop-table-text-truncate pr-2'},
              {"data": "casZacetka",
                render: function(data:any, type:any, row:any){
                  if(data == null || type === "sort" || type === "type"){
                    return data;
                  } 
                  return moment(data).format("DD.MM.YYYY");           
                }
              },
              {"data": "casKonca",
                render: function(data:any, type:any, row:any){
                  if(data == null || type === "sort" || type === "type"){
                    return data;
                  } 
                  return moment(data).format("DD.MM.YYYY");           
                }
              },
              {"data": "rokZakljucka"},
              {"data": "nazivPoverjenik", className: 'saop-table-text-truncate'}
            ]
            ,order: [[1, 'desc']]
        };
        this.treeTable.init(_params);
      }
    //
}