﻿import { global, setFirstFormControlToFocus } from "./Common/saop-common";
import { SaopUtils } from "./Core/saop-utils";
import { SaopTogglePanel } from "./Components/saop-toggle-panel";
import { SaopInputMask } from "./Components/saop-inputmask";
import { SaopCheckboxSwitch } from "./Components/saop-checkbox-switch";
import { SaopMultiselect  } from "./Components/saop-multiselect";
import { SaopViewSpinner } from './Components/saop-view-spinner';
import { SaopRightSidebar } from './Components/saop-right-sidebar';
import { SaopCalendar  } from "./Components/saop-calendar";
import { MenuSidebar } from "./Components/saop-menu-sidebar";
import { SaopMessageDlg } from "./Components/saop-message-dlg";
import { SaopPopupDialog } from './Components/saop-popup-dialog'
import { SaopFloatingLabel } from "./Components/saop-floating-label";
import { SaopRegView } from "./REG/saop-regUra-view";
import { SaopRegUraDanView } from "./REG/saop-regUraDan-view"
import { SaopRegUraDogodekView } from "./REG/saop-regUraDogodek-view";
import { SaopRegAbsenceEventsView } from "./REG/saop-regAbsenceEvents-view";
import { SaopRegTodayEventsView } from "./REG/saop-regTodayEvents-view";
import { SaopDashboardView } from "./Dashboard/saop-dashboard-view";
import { SaopRegAttendanceView } from './REG/saop-regAttendance-view';
import { SaopAbsenceConfirmationView } from './AbsenceConfirmation/saop-absenceConfirmation-view';
import { SaopAbsenceConfirmationPendingView } from "./AbsenceConfirmation/saop-absenceConfirmation-pending-view";
import { SaopAbsenceConfirmationProcessedView } from "./AbsenceConfirmation/saop-absenceConfirmation-processed-view";
import { SaopAbsenceConfirmationCalendarView } from "./AbsenceConfirmation/saop-absenceConfirmationCalendar-view";
import { SaopSplDocumentView } from "./SPLDocument/saop-splDocument-view";
import { SaopAbsenceConfirmationDetailsEvents } from "./AbsenceConfirmation/saop-absenceConfirmation-details-events";
import { SaopAbsenceConfirmationPartiallyApproveView } from "./AbsenceConfirmation/saop-absenceConfirmation-partially-approve-view";
import { OPNPotniNalogiGrid } from "./OPN/OPNPotniNalogi-grid";
import { OPNPotniNalogiArhivGrid } from "./OPN/OPNPotniNalogiArhiv-grid";
import { OPNPotniNalogiAllGrid } from "./OPN/OPNPotniNalogiAll-grid";
import { SaopSettingsView } from "./Settings/saop-settings-view";
import { SaopRegAbsenceMainView } from "./REG/saop-regAbsenceMain-view";
import { SaopOrganigramView } from "./Organization/saop-organigram-view";
import { SaopRegAttendanceAllEmployeesView } from './REG/saop-regAttendanceAllEmployees-view';
import { SaopChangePwdView } from './Account/saop-change-pwd-view';
import { NDKInternalOrders } from "./NDK/NDKInternalOrders";
import { NDKInternalOrderEditForm } from "./NDK/NDKInternalOrderEditForm";
import { KeEployeeLeaveListGrid } from './AbsenceConfirmation/saop-employeeLeaveList-grid'
import { KEMyGrades } from './KE/KEMyGrades';
import { KEMyGradeDetails } from './KE/KEMyGradeDetails';
import { KEGradesPeriod } from './KE/KEGradesPeriod';
import { SaopGoogleMaps } from './Components/saop-googlemaps';
import { SaopRegAttendanceAllEmployeesAnalysisView } from "./REG/saop-regAttendanceAllEmployeesAnalysis-view";
import { LIKVCakalnaVrstaGrid } from "./LIKV/LIKVCakalnaVrsta-grid";
import { LIKVCakalnaVrstaView } from "./LIKV/LIKVCakalnaVrsta-view";
import { SaopAppSettingsView } from "./AppSettings/saop-appsettings-view";
import { LIKVCakalnaVrstaArhivGrid } from "./LIKV/LIKVCakalnaVrstaArhiv-grid";
import { SaopRegAttendanceAllEmployeesExcessHoursGrid } from "./REG/saop-regAttendanceAllEmployeesExcessHours-grid";
import { LIKVNivojiPotrjevanjaGrid } from "./LIKV/LIKVNivojiPotrjevanja-grid";

/*import { extend } from "jquery";*/

export function checkCompatibility(): boolean {
    global.ConsoleLogDegug("checkCompatibility");
    let utils = new SaopUtils();
    return utils.checkCompatibility();
}

export function initClientLogModeValue() {
    global.debugMode = global.GetClientLogModeStorageValue();
}

export function initSiteBaseUrlValue() {
    global.siteBaseUrl = global.getSiteBaseUrl()
}

export function initializeSiteJS() {
    global.ConsoleLogDegug("initializeSiteJS");
    //new MenuSidebar().init();
    new SaopTogglePanel().init();

    document.addEventListener('keydown', function (e) {
        if (e.ctrlKey && e.altKey && e.keyCode == 79) {
            // CTRL + ALT + O
            global.SetClientLogModeStorageValue();
            global.debugMode = global.GetClientLogModeStorageValue();
        }
    });

    global.setHelpLink();
}

export function setFirstControlToFocus(formId: string) {
    setFirstFormControlToFocus(formId);
}

export class SaopTogglePanelEx extends SaopTogglePanel{};

export class SaopInputMaskEx extends SaopInputMask{};

export class SaopCheckboxSwitchEx extends SaopCheckboxSwitch{};

export class SaopMultiselectEx extends SaopMultiselect{};

export class SaopCalendarEx extends SaopCalendar{};

export class SaopMessageDlgEx extends SaopMessageDlg{};

export class SaopPopupDialogEx extends SaopPopupDialog{};

export class SaopRegViewEx extends SaopRegView{};

export class SaopRegUraDanViewEx extends SaopRegUraDanView{};

export class SaopRegUraDogodekViewEx extends SaopRegUraDogodekView{};

export class SaopRegAbsenceEventsViewEx extends SaopRegAbsenceEventsView { };

export class SaopRegTodayEventsViewEx extends SaopRegTodayEventsView { };

export class SaopDashboardViewEx extends SaopDashboardView { };

export class SaopViewSpinnerEX extends SaopViewSpinner { };

export class SaopRightSidebarEx extends SaopRightSidebar { };

export class SaopRegAttendanceViewEx extends SaopRegAttendanceView { };

export class SaopAbsenceConfirmationViewEx extends SaopAbsenceConfirmationView { };

export class SaopAbsenceConfirmationPendingViewEx extends SaopAbsenceConfirmationPendingView { }; 

export class SaopAbsenceConfirmationProcessedViewEx extends SaopAbsenceConfirmationProcessedView { };

export class SaopAbsenceConfirmationCalendarViewEx extends SaopAbsenceConfirmationCalendarView { };

export class SaopSplDocumentViewEx extends SaopSplDocumentView { };

export class SaopAbsenceConfirmationDetailsEventsEx extends SaopAbsenceConfirmationDetailsEvents { };

export class SaopAbsenceConfirmationPartiallyApproveViewEx extends SaopAbsenceConfirmationPartiallyApproveView { };

export class OPNPotniNalogiGridEx extends OPNPotniNalogiGrid { };

export class OPNPotniNalogiGridArhivEx extends OPNPotniNalogiArhivGrid { };

export class OPNPotniNalogiAllGridEx extends OPNPotniNalogiAllGrid { };

export class SaopFloatingLabelEx extends SaopFloatingLabel { };

export class SaopSettingsViewEx extends SaopSettingsView { };

export class SaopRegAbsenceMainViewEx extends SaopRegAbsenceMainView { };

export class SaopOrganigramViewEx extends SaopOrganigramView { };

export class SaopRegAttendanceAllEmployeesViewEx extends SaopRegAttendanceAllEmployeesView { };

export class SaopChangePwdViewEx extends SaopChangePwdView { };

export class NDKInternalOrdersEx extends NDKInternalOrders { };

export class NDKInternalOrderEditFormEx extends NDKInternalOrderEditForm { };

export class KeEployeeLeaveListGridEx extends KeEployeeLeaveListGrid { };

export class KEMyGradesEx extends KEMyGrades { }

export class KEMyGradeDetailsEx extends KEMyGradeDetails { };

export class KEGradesPeriodEx extends KEGradesPeriod { };

export class SaopGoogleMapsEx extends SaopGoogleMaps { };

export class SaopRegAttendanceAllEmployeesAnalysisViewEx extends SaopRegAttendanceAllEmployeesAnalysisView { };

export class LIKVCakalnaVrstaGridEx extends LIKVCakalnaVrstaGrid {};

export class LIKVCakalnaVrstaViewEx extends LIKVCakalnaVrstaView {};

export class SaopAppSettingsViewEx extends SaopAppSettingsView {};

export class LIKVCakalnaVrstaGridArhivEx extends LIKVCakalnaVrstaArhivGrid { };

export class SaopRegAttendanceAllEmployeesExcessHoursGridEx extends SaopRegAttendanceAllEmployeesExcessHoursGrid { };

export class mojInfoMenuSidebarEx extends MenuSidebar { };

export class LIKVNivojiPotrjevanjaGridEx extends LIKVNivojiPotrjevanjaGrid { };
//